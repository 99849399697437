<template>
  <div>
    <h2>Филиалы</h2>

    <branches-table></branches-table>
  </div>
</template>

<script>
import BranchesTable from "@/components/ecologist/branches/branchesTable";
export default {
  name: "branchesMain",
  components: { BranchesTable },
};
</script>

<style scoped></style>

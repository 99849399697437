<template>
  <div>
    <branches-main></branches-main>
  </div>
</template>

<script>
import BranchesMain from "@/components/ecologist/branches/branchesMain";
export default {
  name: "BranchesMainView",
  components: { BranchesMain },
};
</script>

<style scoped></style>
